<template>
  <div class="px-0 lg:px-20">
    <!--Search Bar -->
    <div
      class="
        p-4
        my-5
        flex flex-row
        items-center
        justify-center
        md:justify-between
        rounded-lg
        shadow
        bg-white
      "
    >
      <h1
        class="
          hidden
          md:inline
          text-2xl
          font-semibold
          text-gray-700
          font-EffraR
          flex-1
        "
      >
        <i class="fas fa-user-injured"></i> Patientèle
      </h1>
      <div class="flex flex-row items-center gap-3 md:gap-3 mr-5">
        <input
          v-model="filterName"
          class="
            w-full
            xl:w-300
            bg-white
            border border-solid border-gray-300
            rounded-cu
            px-6
            py-3
          "
          placeholder="Rechercher par Nom ou Prénom"
        />
        <button
          @click="loadPatients()"
          class="
            border-2 border-solid border-nblue
            text-nblue
            py-3
            px-5
            bg-white
            rounded-cu
            font-bold
            text-lg
            cursor-pointer
            transition
            duration-200
            ease-in-out
            hover:bg-nblue
            hover:text-white
            font-EffraR
          "
        >
          Filtrer
        </button>
      </div>
    </div>

    <!--Loader -->
    <div class="h-full w-full" v-if="loader">
      <div
        class="
          bg-white bg-opacity-60
          px-8
          py-2
          h-screen
          w-full
          flex
          justify-center
          items-center
        "
      >
        <loader></loader>
      </div>
    </div>

    <!--Data patients -->
    <div v-if="!loader" class="w-full mx-auto">
      <div
        class="
          bg-white
          border-0 border-b border-solid border-gray-300
          py-3
          px-3
          grid
          gap-1
          grid-cols-1
          xl:grid-cols-3
          shadow
        "
        :class="index === 0 ? 'rounded-t-lg' : ''"
        :key="index"
        v-for="(user, index) in patients"
      >
        <div
          class="
            flex flex-col
            xl:flex-row
            items-center
            justify-center
            xl:justify-start
          "
        >
          <img
            alt="profile image"
            :src="$checkAvatar(user.image)"
            onerror="javascript:this.src='/no-avatar.png'"
            class="
              h-24
              w-24
              xl:h-12
              xl:w-12
              rounded-full
              object-cover
              bg-nblue
              border border-solid border-gray-200
              xl:mr-3
              mb-5
              xl:mb-0
            "
          />
          <div id="details">
            <h3 class="font-semibold text-xl mb-2 xl:mb-0 font-EffraR">
              {{ user.lastName }} {{ user.firstName }}
            </h3>
          </div>
        </div>
        <div class="flex flex-row items-center justify-center xl:justify-start">
          <span class="font-EffraL text-base xl:mr-1"></span
          >{{ user.gender === "MALE" ? "Homme" : "Femme" }}
        </div>
        <div class="flex justify-center xl:justify-end">
          <button
            @click="$router.push('/pharmacy/patients/' + user._id + '/drugs')"
            class="
              mr-5
              font-EffraR
              mt-5
              xl:mt-0
              w-full
              xl:w-auto
              bg-transparent
              hover:bg-blackdok
              text-blackdok
              hover:text-white
              border-2 border-solid border-blackdok
              py-2
              px-5
              rounded-cu
              cursor-pointer
            "
          >
            Médicaments
          </button>
          <button
            @click="
              $router.push('/pharmacy/patients/' + user._id + '/ordonnances')
            "
            class="
              font-EffraR
              mt-5
              xl:mt-0
              w-full
              xl:w-auto
              bg-transparent
              hover:bg-blackdok
              text-blackdok
              hover:text-white
              border-2 border-solid border-blackdok
              py-2
              px-5
              rounded-cu
              cursor-pointer
            "
          >
            Ordonnances
          </button>
        </div>
      </div>

      <!-- Null Data patients -->
      <div
        v-if="patients.length == 0"
        class="bg-white 0 px-8 py- w-full flex justify-center items-center"
      >
        <img
          alt="vide"
          src="/svg/null_data.svg"
          class="max-h-300 xl:max-h-500"
        />
      </div>

      <!-- pagination -->
      <div class="mt-4 py-5 flex justify-center items-center" v-if="pages > 1">
        <div class="flex flex-row">
          <div>
            <a
              href="javascript:void(0)"
              @click="
                page--;
                loadPatients();
              "
              :class="page == 1 ? 'disable ' : ''"
              class="
                px-4
                py-4
                mx-5
                border-0
                shadow-sm
                text-nblue
                rounded-full
                cursor-pointer
                hover:shadow-lg
                hover:text-gray-100
                hover:bg-nblue
              "
              ><i class="fa fa-angle-left" aria-hidden="true"></i
            ></a>
          </div>
          <div v-for="item in pages" :key="item">
            <a
              href="javascript:void(0)"
              @click="
                page = item;
                loadPatients();
              "
              :class="
                page == item
                  ? 'shadow-lg text-gray-100 bg-nblue disable'
                  : 'text-nblue'
              "
              class="
                px-4
                py-4
                mx-1
                border-0
                shadow-sm
                rounded-full
                cursor-pointer
                hover:shadow-lg
                hover:text-gray-100
                hover:bg-nblue
              "
              >{{ item }}</a
            >
          </div>
          <div>
            <a
              href="javascript:void(0)"
              @click="
                page++;
                loadPatients();
              "
              :class="page == pages || pages == 0 ? 'disable ' : ''"
              class="
                px-4
                py-4
                mx-5
                border-0
                shadow-sm
                text-nblue
                rounded-full
                cursor-pointer
                hover:shadow-lg
                hover:text-gray-100
                hover:bg-nblue
              "
              ><i class="fa fa-angle-right" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import loader from "@/views/global-components/loader";
export default {
  data() {
    return {
      page: 1,
      pages: 1,
      patients: [],
      loader: true,
      filterName: null,
    };
  },
  async mounted() {
    await this.loadPatients();
    if (this.patients.length <= 0) this.loader = false;
  },
  components: { loader },
  methods: {
    moment() {
      return moment;
    },
    async loadPatients() {
      this.loader = true;
      try {
        const { ok, data, pages } = await this.$store.dispatch(
          "laboratory/GET_PATIENTS",
          { page: this.page, filterName: this.filterName }
        );
        if (ok) {
          this.pages = pages;
          this.patients = data;
          this.filterName = null;
        }
      } catch (err) {
        this.loader = false;
      }
      this.loader = false;
    },
  },
};
</script>
<style scoped>
.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
